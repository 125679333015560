import React from 'react';
import classnames from 'classnames';

interface IProps {
  question: string;
  stories: {
    story: {
      story: string;
    };
  }[];
  styles?: string[];
}

const SuccessStories = ({ stories, question, styles }: IProps) => {
  return (
    <section className={classnames(`${styles}`)}>
      <h2>{question}</h2>
      <ul>
        {stories.map((story: any, i: number) => (
          <li key={i}>{story.story.story}</li>
        ))}
      </ul>
    </section>
  );
};

export default SuccessStories;
