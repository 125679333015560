import React from 'react';
import classnames from 'classnames';

const DoubleLineList = ({
  title,
  responses,
}: {
  title: string;
  responses: string[];
}) => {
  return (
    <section>
      <h2>{title}</h2>
      <ul className={classnames('cols')}>
        {responses.map((res, i) => (
          <li key={i}>{res}</li>
        ))}
      </ul>
    </section>
  );
};

export default DoubleLineList;
