import * as React from 'react';
import { Link, graphql } from 'gatsby';
import SEO from '../components/ui/Seo';
import ProgramDetailLayout from '../components/ui/Layout/ProgramDetailLayout';
import ProgramName from '../components/ProgramDetail/ProgramName';
import FeatureImage from '../components/ProgramDetail/FeatureImage';

import SingleLine from '../components/ProgramDetail/Responses/SingleLine';
import List2Col from '../components/ProgramDetail/Responses/List2Col';
import SingleLineList from '../components/ProgramDetail/Responses/SingleLineList';
import DoubleLineList from '../components/ProgramDetail/Responses/DoubleLineList';
import SingleLine3Col from '../components/ProgramDetail/Responses/SingleLine3Col';

import List1Col from '../components/ProgramDetail/Responses/List1Col';
import SuccessStories from '../components/ProgramDetail/Responses/SuccessStories';
import { POLICY_ADVOCACY_MENU } from '../utils/constants';

import s from './ProgramDetail.module.scss';
import VideoPlayer from '../components/ui/VideoPlayer/VideoPlayer';
import SingleLineLinkList from '../components/ProgramDetail/Responses/SingleLineLinkList';
import * as ADIcon from '../svgs/advocacy.svg';
import Quote from '../components/ProgramDetail/Responses/Quote';
import YesNo from '../components/ProgramDetail/Responses/YesNo';
import TheoryOfChange from '../components/ProgramDetail/TheoryOfChange';
import LineAndDownArrow from '../components/ui/LineAndDownArrow/LineAndDownArrow';
const PolicyAdvocacyProgramDetail = ({
  // keep to pass variables set in gatsby-node.js
  pageContext,
  data,
}: {
  pageContext: { programName: string; slug: string };
  data: {
    allContentfulPolicyAdvocacyQuestions: { edges: any };
    allContentfulProgram: { edges: any };
    allContentfulVideo: { edges: any };
  };
}) => {
  if (data === undefined) {
    return null;
  }
  let survey = data.allContentfulPolicyAdvocacyQuestions.edges[0].node;
  let program =
    data.allContentfulProgram.edges.length > 0
      ? data.allContentfulProgram.edges[0].node
      : '';
  let video =
    data.allContentfulVideo.edges[0] &&
    data.allContentfulVideo.edges[0].node.video.localFile.url;
  let clientQuote =
    program.quotes && program.quotes[0] && program.quotes[0].quote.quote;
  let quoteSource = program.quotes && program.quotes[0].source;

  let links = program.linksAndDownloads;

  let policyAreas = survey.areasOfPolicyInfluence;
  // survey.areasOfPolicyInfluenceOther,
  const hasImages = (): boolean => {
    return program.imageLinks !== null;
  };

  const hasVideos = (): boolean => {
    return (
      video !== undefined ||
      (program.videoLinks && program.videoLinks.length > 0)
    );
  };

  function getVideo() {
    let vid;
    if (video) {
      vid = video;
    } else {
      vid = program.videoLinks[0];
    }

    return vid;
  }

  // console.log('Video', video);
  // console.log('Survey ', survey);
  // console.log('Program ', program);

  return (
    <ProgramDetailLayout menuItems={POLICY_ADVOCACY_MENU}>
      <SEO title={survey.programName} />
      <p className="link-icon text-right">
        <a style={{ fontSize: '24px' }}>
          <ADIcon style={{ marginRight: '16px' }} /> Policy/Advocacy
          <br />
          <span style={{ fontSize: '18px' }}>{program.programSubType}</span>
        </a>
      </p>
      <article className={'module-program'}>
        <header style={{ borderBottomWidth: 0 }}>
          <ProgramName programName={survey.programName} />
        </header>
        {hasImages() && !hasVideos() && (
          <FeatureImage
            programName={survey.programName}
            images={program.imageLinks}
          />
        )}
        {hasVideos() && <VideoPlayer url={getVideo()} />}

        {clientQuote && <Quote quote={clientQuote} source={quoteSource} />}

        {/* PROGRAM PROFILE */}
        <div id={'profile'}>
          <p className={s.section__title}>Program Profile</p>
          {program.implementingOrganization && (
            <SingleLine
              question={'Implementing organization'}
              answer={program.implementingOrganization}
            />
          )}

          <SingleLine3Col
            headings={['Country', 'Headquarters', 'Start Year']}
            responses={[
              program.countryRegion,
              program.headquarters,
              program.startYear,
            ]}
          />

          {program.website && (
            <SingleLine question={'website'} answer={program.website} link />
          )}

          {program.description && program.description.description && (
            <SingleLine
              question={'Program Description'}
              answer={program.description.description}
            />
          )}
        </div>
        {/* THE BIG PICTURE */}
        <div id={'bigPicture'}>
          <p className={s.section__title}>The Big Picture</p>
          {survey.childContentfulPolicyAdvocacyQuestionsProblemAddressedTextNode
            .problemAddressed && (
            <SingleLine
              question={'Problem Addressed'}
              answer={
                survey
                  .childContentfulPolicyAdvocacyQuestionsProblemAddressedTextNode
                  .problemAddressed
              }
            />
          )}
          {survey.programObjective && (
            <SingleLine
              question={'Program Objective'}
              answer={survey.programObjective}
            />
          )}
          {survey.childContentfulPolicyAdvocacyQuestionsWhatWouldMakeYourWorkUnnecessaryTextNode && (
            <SingleLine
              question={'What would make your work unnecessary?'}
              answer={
                survey
                  .childContentfulPolicyAdvocacyQuestionsWhatWouldMakeYourWorkUnnecessaryTextNode
                  .whatWouldMakeYourWorkUnnecessary
              }
            />
          )}
          {survey.mainPracticalChallengesFaced && (
            <List2Col
              title={'Main Practical Challenges Faced'}
              responses={survey.mainPracticalChallengesFaced}
            />
          )}
          {survey.mainGovernmentLaws && (
            <SingleLineList
              question={
                'Main government laws, policies, or programs that have led to positive change in the last 40 years:'
              }
              responses={survey.mainGovernmentLaws}
              field={'law'}
            />
          )}
        </div>
        {/* Program strategies/Targets */}
        <div id={'strategiesAndTargets'}>
          <p className={s.section__title}>Program Strategies/Targets</p>
          {policyAreas && (
            <List2Col
              title={'Areas of Policy Influence'}
              responses={policyAreas}
            />
          )}
          {survey.advocacyTargetGroups && (
            <DoubleLineList
              title={'Advocacy Target Groups'}
              responses={survey.advocacyTargetGroups}
            />
          )}
        </div>
        {/* THEORY OF CHANGE */}
        <div id={'theoryOfChange'}>
          <p className={s.section__title}>Theory of Change</p>
          <TheoryOfChange />
          {survey.activitiesprogramFocus && (
            <List2Col
              title={'Activities/Program Focus'}
              responses={survey.activitiesprogramFocus}
            />
          )}
          {survey.strategiesToInfluencePolicy && (
            <SingleLineList
              question={'strategies to influence policy'}
              responses={survey.strategiesToInfluencePolicy}
            />
          )}
          {survey.outputs && (
            <>
              <LineAndDownArrow />
              <List2Col title={'Outputs'} responses={survey.outputs} />
            </>
          )}
          {survey.shortTermOutcomes && (
            <>
              <LineAndDownArrow />
              <SingleLineList
                question={'Short-Term Outcomes'}
                responses={survey.shortTermOutcomes}
              />
            </>
          )}
          {survey.longTermOutcomes && (
            <>
              <LineAndDownArrow />
              <SingleLineList
                question={'Long-Term Outcomes'}
                responses={survey.longTermOutcomes}
              />
            </>
          )}
          {survey.longTermChange && (
            <>
              <LineAndDownArrow />
              <SingleLineList
                question={'Long-Term Change'}
                responses={survey.longTermChange}
              />
            </>
          )}

          {survey.programRationale && (
            <SingleLineList
              question={'Program Rational'}
              responses={survey.programRationale}
            />
          )}
          {survey.ingredientsForSuccess && (
            <SingleLineList
              question={'Ingredients for Success'}
              responses={survey.ingredientsForSuccess}
            />
          )}
        </div>
        {/* Implementation */}
        <div id={'implementation'}>
          <p className={s.section__title}>Implementation</p>
          {survey.yearsImplemented && (
            <SingleLine
              question={'Years Implemented'}
              answer={survey.yearsImplemented}
            />
          )}
          {survey.outputsCompletedIn20172018 && (
            <List2Col
              title={'Outputs Completed in 2017/2018'}
              responses={survey.outputsCompletedIn20172018}
            />
          )}
          {hasImages() && hasVideos() && (
            <FeatureImage
              programName={survey.programName}
              images={program.imageLinks}
            />
          )}
        </div>
        {/* Cost */}
        <div id={'cost'}>
          <p className={s.section__title}>Cost</p>
          <SingleLine3Col
            headings={[
              'Budget Year',
              'Main funding source',
              'Annual Program Budget',
            ]}
            responses={[
              survey.budgetYear,
              survey.mainFundingSource,
              survey.annualProgramBudget,
            ]}
          />
        </div>
        {/* Evaluating Success */}
        <div id={'evaluatingSuccess'}>
          <p className={s.section__title}>Evaluating Success</p>
          {survey.dataCollected && (
            <List2Col
              title={'Data Collected'}
              responses={survey.dataCollected}
            />
          )}
          {survey.indicatorsTracked && (
            <List2Col
              title={'Indicators Tracked'}
              responses={survey.indicatorsTracked}
            />
          )}
          <YesNo
            question={'Evaluations Conducted'}
            answer={survey.evaluationConducted}
          />
          {survey.typeOfEvaluationsConducted && (
            <List1Col
              title={'Type of Evaluations Conducted'}
              responses={survey.typeOfEvaluationsConducted}
            />
          )}
          {survey.childContentfulPolicyAdvocacyQuestionsEvaluationFindingsTextNode && (
            <SingleLine
              question={'Evaluation Findings'}
              answer={
                survey
                  .childContentfulPolicyAdvocacyQuestionsEvaluationFindingsTextNode
                  .evaluationFindings
              }
            />
          )}
          {survey.evaluationWeblinks && (
            <SingleLineLinkList
              question={'Evaluation links'}
              responses={[survey.evaluationWeblinks]}
            />
          )}
          {survey.successStories && survey.successStories.length > 0 && (
            <SuccessStories
              question={'Success Stories'}
              stories={survey.successStories}
            />
          )}
        </div>
        {/* Additional Materials */}
        {links && (
          <div id={'additionalMaterials'}>
            <p className={s.section__title}>Additional Materials</p>
            <SingleLineLinkList
              question={'Links & Downloads'}
              responses={links ? links : 'N/A'}
            />
          </div>
        )}
      </article>

      <Link to="/">
        <p style={{ color: 'black' }}>Go back to the homepage</p>
      </Link>
    </ProgramDetailLayout>
  );
};

export default PolicyAdvocacyProgramDetail;

export const query = graphql`
  query PolicyAdvocacyProgramDetailQuery($programName: String!) {
    allContentfulProgram(filter: { programName: { eq: $programName } }) {
      edges {
        node {
          imageLinks
          videoLinks
          socialMedia
          website
          programSubType
          linksAndDownloads
          implementingOrganization
          countryRegion
          headquarters
          startYear
          programType
          description {
            description
          }
          quotes {
            quote {
              quote
            }
            source
            sourceTitle
          }
        }
      }
    }
    allContentfulVideo(
      filter: { program: { programName: { eq: $programName } } }
    ) {
      edges {
        node {
          video {
            localFile {
              url
            }
          }
        }
      }
    }
    allContentfulPolicyAdvocacyQuestions(
      filter: { programName: { eq: $programName } }
    ) {
      edges {
        node {
          activitesprogramFocusOther
          activitiesprogramFocus
          advocacyTargetGroups
          budgetYear
          annualProgramBudget
          yearsImplemented
          mainFundingSource
          areasOfPolicyInfluence
          areasOfPolicyInfluenceOther
          boardOfDirectorsSize
          childContentfulPolicyAdvocacyQuestionsDataCommentsTextNode {
            dataComments
            internal {
              mediaType
            }
          }
          childContentfulPolicyAdvocacyQuestionsEvaluationFindingsTextNode {
            evaluationFindings
            internal {
              mediaType
            }
          }
          childContentfulPolicyAdvocacyQuestionsIndicatorsTrackedCommentsTextNode {
            indicatorsTrackedComments
            internal {
              mediaType
            }
          }
          childContentfulPolicyAdvocacyQuestionsProblemAddressedTextNode {
            problemAddressed
            internal {
              mediaType
            }
          }
          childContentfulPolicyAdvocacyQuestionsWhatWouldMakeYourWorkUnnecessaryTextNode {
            whatWouldMakeYourWorkUnnecessary
          }
          childContentfulPolicyAdvocacyQuestionsWhyTextNode {
            why
            internal {
              mediaType
            }
          }
          contentful_id
          dataCollected
          dataComments {
            dataComments
            internal {
              mediaType
            }
          }
          evaluationConducted
          evaluationWeblinks
          evaluationFindings {
            evaluationFindings
          }
          id
          indicatorsTracked
          indicatorsTrackedComments {
            indicatorsTrackedComments
            internal {
              mediaType
            }
          }
          ingredientsForSuccess
          longTermChange
          longTermOutcomes
          mainFundingSource
          mainGovernmentLaws {
            id
            law {
              internal {
                content
              }
            }
          }
          mainPracticalChallengesFaced
          numberOfOtherStaff
          numberOfStaff
          outputs
          outputsCompletedIn20172018
          percentBoardOfDirectorsIncarceratedformerlyI
          percentIncarceratedformerlyIncarceratedOther
          percentStaffIncarceratedformerlyIncarcerated
          percentTotalIndividualsInvolvedWithProgram
          programName
          programObjective
          programRationale
          shortTermOutcomes
          strategiesToInfluencePolicy
          problemAddressed {
            problemAddressed
            internal {
              mediaType
            }
          }
          totalProgramSize
          typeOfEvaluationsConducted
          why {
            why
            internal {
              mediaType
            }
          }
          successStories {
            story {
              story
            }
          }
        }
      }
    }
  }
`;
